header.main {
    height: 100vh;
}

main {
    section:not(.player) {
        padding: 8rem 0;
        // min-height: 100vh;
    }
}

section.about {
    background-color: #000;
    // padding: 5rem 0;
    .sectionTitle{
        color: #eee;
    }
    .artistGrid {
        margin: 0 10rem;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 5rem;

        @include respond-to("desktop-13") {
            margin: 1.5rem;
            gap: 3rem;
        }

        .artistCard {
            // outline: 1px solid red;

            display: flex;
            flex-direction: column;
            // justify-content:  flex-start;
            @include respond-to("desktop-13") {
                align-items: center;
            }
            &:nth-child(2) {
                margin-top: 150px;
                @include respond-to("desktop-13") {
                    margin-top: 0;
                }
            }
        }

        p {
            margin-top: 2rem;
            color: #ddd;
        }
        figure {
            width: 19rem;
            margin-right: 3rem;
            position: relative;
            transition: 1s;

            .tagline {
                position: absolute;
                transform: rotate(-90deg);
                transform-origin: right;
                top: 0;
                right: -20px;
            }
            .artistName {
                position: absolute;
                background-color: $color-secondary;
                font-size: 1.8rem;
                display: block;
                left: 20px;
                bottom: -20px;
                white-space: nowrap;
            }

            figcaption {
                color: #fff;
                font-family: $font-heading;
            }
            img {
                width: 100%;
                border-radius: 5px;
                filter: grayscale(1);
            }
        }
    }
}

section#albums {
    padding: 5rem;

    @include respond-to("desktop-13") {
        padding: 1.5rem;
    }

    .gridAlbums {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        justify-items: center;
        gap: 1rem;
        row-gap: 1.2rem;

        figure {
            cursor: pointer;
            position: relative;
            color: #999;
            &::before {
                content: " ";
                text-align: center;
                position: absolute;
                z-index: 999999;
                font-size: 1.3rem;
                opacity: 0;
                width: 100px;
                transition: 0.2s;
                background-size: cover;
                background-position: center;
                height: 100px;
                background-image: url("https://cdn.iconscout.com/icon/free/png-256/play-circle-round-resume-arrow-player-30543.png");
                color: $color-primary;
                text-transform: uppercase;
                font-weight: 600;
                top: 45%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                filter: invert(1);
                background-blend-mode: exclusion;
            }
            &::after {
                content: " ";
                display: block;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
                width: 100%;
                height: 85%;
                opacity: 0.7;
                position: absolute;
                top: 0;
                opacity: 0;
            }
            &:hover:before {
                opacity: 1;
            }

            &:hover::after {
                opacity: 0.5;
            }

            &:hover img {
                // filter: opacity(0.5);
                // transform: scale(1.02);
            }
            &:hover figcaption {
                color: $color-primary;
            }

            figcaption {
                margin-top: 1rem;
            }
            img {
                border-radius: 5px;

                width: 100%;
                max-width: 320px;
            }
        }
    }
}



section#artistasConvidados {
    background-color: #f9f9f9;
    padding: 5rem;

    .sectionTitle {
        padding-top: 3rem;
    }
    @include respond-to("desktop-13") {
        padding: 1.2rem;
        padding-bottom: 2.4rem;
    }

    .artistasConvidados__grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        gap: 2rem;
        @include respond-to("desktop-13") {
            grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
            gap: 1rem;
            row-gap: 2.5rem;
        }
        #jokerHigh {
        }

        .artistasConvidados__grid__item {
            width: 100%;
            height: 0;
            // padding-top: calc(533 / 799 * 100%);
            padding-top: calc(3.5 / 3 * 100%);
            position: relative;
            margin-bottom: 2rem;
            .artistasConvidados__grid__wrapper {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                // background-color: #999;
                width: 100%;
                .artistasConvidados__grid__image {
                    height: calc(100%);

                    figcaption {
                        font-weight: 600;
                        padding-top: 1rem;
                    }
                    img {
                        border-radius: 5px;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

.space {
    height: 300px;
}

.sectionTitle {
    display: flex;
    justify-content: center;
    padding-bottom: 5rem;
    h2 {
        text-align: center;
        font-family: $font-heading;
        font-size: 2.8rem;
    }
}

.mainTitle {
    overflow: hidden;
}

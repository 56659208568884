//! import google fonts
// @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700');
// OR use @font-face like below


@import url("https://use.typekit.net/epq1pif.css");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');


$font-heading: "aviano", serif;
$font-body: "Montserrat", sans-serif;;

html {
  font-size: 16px;
}

body {
  font-family: $font-body;
  font-size: 1rem;
  color: #382711;
}

h1 {
  font-family: $font-heading, serif;
  font-size: 2.6rem;
  font-weight: 900;
}

p{
  line-height: 1.6rem;  
}
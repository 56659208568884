.player {
    width: 100%;
    background: #fcfcfc;
    display: block;
    transform: translateY(100%);
    // transform: translateY(0);
    position: fixed;
    transition: 0.3s;
    height: 100%;
    top: 0;
    z-index: 9999999;
    @include respond-to("desktop-13") {
        padding-bottom: 55px;
        overflow: scroll;
    }
}

.player > .container {

    display: grid;
    gap: 1.2rem;
    padding: 1rem 0;
    grid-template-columns: 50% 50%;

    @include respond-to("desktop-13") {
        overflow: scroll;
        height: auto;
        grid-template-columns: 100%;
        padding: 0;
    }

    .col {
        width: 100%;
    }
    .albuns {
        align-self: center;
        justify-items: center;
    }
    .tabs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        li {
            vertical-align: middle;
            text-align: center;
            font-weight: 600;
            border-bottom: 2px solid #eee;
            color: #c4c4c4;
            cursor: pointer;
            

            padding: 1rem;
            display: inline-block;
        }
        li[active] {
            color: $color-secondary;
            border-bottom: 3px solid $color-secondary;
        }
    }

    [data-tab="content"] {
        display: none;
        height: calc(100vh - 13rem);
        overflow-y: hidden;
        @include respond-to("desktop-13") {
            overflow-y: visible;
            height: auto;
        }
    }
    [data-tab="content"]:hover {
        overflow-y: auto;
        position: relative;
    }
    ::-webkit-scrollbar {
        display: block;
        background-color: #eee;
        position: absolute;
        width: 15px;
        margin: 0;
        padding: 0;
        left: 0;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #ccc;
        opacity: 0.4;
    }

    [active] {
        display: block;
    }

    button {
        padding: 0.6rem 1.2rem;
        border-radius: 5px;
        border: 2px solid $color-secondary;
        font-weight: 500;
        background-color: unset;
        color: $color-secondary;
        cursor: pointer;
        outline: none;
    }
    .btn-primary {
        background-color: $color-secondary;
        color: #fff;
        border-color: $color-secondary;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;

        align-items: center;

        img {
            width: 330px;
            border-radius: 5px;
            position: relative;
            @include respond-to("desktop-13") {
                width: 250px;
            }
        }
        .contentSlide {
            width: fit-content;
            height: fit-content;
            border-radius: 5px;
        }
    }

    .swiper-slide:not(.swiper-slide-active) img {
        transition: 0.2s;

        transform: scale(0.8);
        filter: grayscale(1) brightness(1.4);
    }
}

.swiper-container {
    padding: 5rem;
    @include respond-to("desktop-13") {
        padding: 2rem 5rem;
    }
}

.swiper-slide-active img {
    box-shadow: 0 1px 0.7px -21px rgba(0, 0, 0, 0.149), 0 2.3px 1.6px -21px rgba(0, 0, 0, 0.214),
        0 4.4px 3px -21px rgba(0, 0, 0, 0.265), 0 7.8px 5.4px -21px rgba(0, 0, 0, 0.316),
        0 14.6px 10px -21px rgba(0, 0, 0, 0.381), 0 35px 24px -21px rgba(0, 0, 0, 0.53);
    @include respond-to("desktop-13") {
        box-shadow: 0 0px 15.5px rgba(0, 0, 0, 0.179), 0 0px 30px rgba(0, 0, 0, 0.18);
    }
}

.swiper-button-prev,
.swiper-button-next {
    width: 40px;
    height: 40px;
    background-color: $color-secondary;
    border-radius: 10px;
    i {
        color: #fff;
    }
}

.swiper-button-next::after,
.swiper-button-prev::after {
    content: " ";
    z-index: 999999;
}

i {
    font-size: 1.7rem;
    vertical-align: middle;
}


header.fullscreen > .container {
    height: 60px;
    display: flex;    
    align-items: center;
    justify-content: space-between;

    span {
        font-size: 0.9rem;
        font-weight: 600;
        cursor: pointer;
        text-transform: uppercase;
        &:hover{
            opacity: .8;
        }
    }
    i {
        margin-right: 0.5rem;
    }
}

.controls {
    margin: 2rem 0;
    i {
        font-size: 1rem;
    }
}

.musicCard {
    * {
        color: $color-primary;
    }
    display: flex;

    flex-wrap: wrap;
    opacity: 0.75;
    padding: 0.2rem;

    border-bottom: 1px solid #e2e2e2 !important;

    width: calc(44vw - 15px);
    @include respond-to("desktop-13") {
        width: 100%;
    }

    &:last-of-type {
        border-bottom: none;
    }

    .lyric {
        border-top: 1px solid #eee;
        padding: 1rem;
        flex-grow: 2;
        width: 100%;
        margin-top: 1rem;
        display: none;
        
    }

    &:hover {
        opacity: 1;
    }

    button.btn-player {
        border: 0;
        margin: 3px;
        padding: 0 0.2rem;
        i {
            padding: 0;
        }
    }
    button.lyricBtn{
        // border: 2px solid  $color-secondary;
        font-weight: 600;
        font-size: .9rem;
        background-color: #eee;
        color: $color-primary;
        padding: 0rem 1.2rem;
        i{
            padding: 0rem;
            // color: $color-secondary;

        }
    }

    .musicInfo {
        cursor: pointer;
        padding: 0.5rem 0.8rem;
        flex-grow: 1;
    }
    h4 {
        font-weight: 600;
    }
    h5 {
        font-weight: 500;
    }
}



.albumInfo {
    font-family: $font-heading;
    h3 {
        opacity: 0.6;
        font-size: 0.9rem;
    }
}

small {
    font-size: 0.7rem;
}

li {
    line-height: 1.6rem;
}

table {
    width: 100%;
}

td:nth-of-type(2n + 1) {
    width: 65px;
    padding-right: 1rem;
}

.globalPlayerContainer {
    position: fixed;

    z-index: 99999999;

    bottom: 0;
    display: none;

    width: 100%;

    justify-content: center;
    background-color: #eee;
    @include respond-to("desktop-13") {


        width: 100%;
    }
    .container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        @include respond-to("desktop-13") {
            justify-content: flex-start;
        }
    }
}

.plyr__controls {
    flex-grow: 1;
    width: 85vw;

    * {
        color: $color-primary;
    }
}
[playing] {
    opacity: 1;
    background-color: #f9f9f9;
    .musicInfo{
        color: $color-secondary;
        
    }
    
    .uil-play-circle:before {
        content: "\ea48";
        color: #fff;
        color: $color-primary;
        color: $color-secondary;
    }

    * {
        color: #fff;
        color: $color-primary;
    }
}


.toggleGlobalPlayer {

    cursor: pointer;
    i {
        font-size: 1.2rem;
    }
    z-index: 99999999999;
}

[open] {
    display: block;
    transform: translateY(0) !important;

}
[overflowHidden] {
    overflow: hidden !important;
}


.lyricBtn{
    background-color: $color-secondary;
    color:#eee ;

}
//? set your brand colors

$white: #fff;

$light-grey: #fafafa;
$grey: #d7d7d7;

$black-soft: #444;
$black: #09090e;

$blue: #2c638f;
$pink: #fff0f5;
$yellow: #fffacd;



$color-primary: #382711;
$color-secondary: #c89350;
$color-tertiary: $yellow;

footer {
    background-color: $color-primary;   
    height: 100vh;
    padding: 5rem 0;
    .container {
        color: #aaa;
      display: flex;
      flex-wrap: wrap;
        // display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        // gap: 1rem;
    }

    .col{
        padding-right: 3rem;
    }

    h3 {
        
        // opacity: .7;
        font-weight: 500;
        hr {
            opacity: 0.15;
        }
        font-size: 1.8rem;
        img {
            vertical-align: text-top;
            display: inline-block;
            margin-right: 1rem;
        }
    }
    
    a:hover{
        opacity: 1;
        color: #fff ;
    }
    small{
        font-weight: 600;
        // color: #55;
        margin:0;
    }
    li {
        font-size: 1rem;
    //  opacity: .6;   
        font-weight: 200;
        margin-bottom: 1rem;
        li {
            padding-top: 1rem;
            img[src$=".svg"] {
                filter: invert(1);
            }
            display: inline-block;
            margin-right: 1rem;
        }
    }
}

:root {
  --plyr-audio-controls-background: #eee;
  --plyr-color-main: #c89350;
}

*::selection {
  background: $pink;
}


html {
  scroll-behavior: smooth;
  @include respond-to("desktop-13") {
    font-size: 15px;
  }
}
html,
body {
  height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
}

.wrapper {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  display: block;
}

ul {
  padding: 0;
  list-style: none;
}

figure{
  margin: 0;
}

.inlineList {
  li {
    display: inline-block;
    margin: 0 0.5rem;
  }
}

.container {
  margin: 0 2rem;
  @include respond-to("desktop-13") {
    margin: 0 1rem;
  }
}

// Loading

body.loading {
  overflow: hidden;
}

.loading::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: $color-primary;
}

.loading::before {
  content: "";
  display: block;
  width: 50px;
  border-radius: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999999999999;
  animation: loading 0.5s linear infinite alternate-reverse;
  background-color: $color-secondary;
}

@keyframes loading {
  0% {
    transform: scale(0) rotate(0);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

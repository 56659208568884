header.main {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @include respond-to("desktop-13") {
        height: 50vh !important;
    }

    nav {
        position: absolute;
        top: 0;
        color: #eee;
        width: 100%;
        line-height: 0;
        .logo {
            font-family: $font-heading;
            text-transform: uppercase;
        }
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        ul {
            li {
                display: inline-block;
                margin: 0 0.5rem;
            }
        }
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 600px;
        background-color: #000;
        top: 0;
        z-index: -9;
        opacity: 0.5;
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    }
    .mainTitle {
        overflow: hidden;
        // margin: 1.5rem 0;
        // outline: 1px solid red;
        height: 6rem;
        @include respond-to("desktop-13") {
            height: 3rem;
        }
        display: flex;
        align-items: center;
    }

    h1 {
        text-transform: uppercase;
        color: #eee;
        font-size: 7rem;
        font-weight: 800;

        transform-origin: center center;
        @include respond-to("desktop-13") {
            font-size: 2.5rem;
        }
    }

    h2 {
        text-transform: uppercase;
        color: #eee;
        font-size: 2rem;
        font-weight: 500;
        @include respond-to("desktop-13") {
            font-size: 1.4rem;
            transform-origin: center center;
        }
    }
    #headerCover {
        object-fit: cover;
        top: 0;
        z-index: -10;
        position: absolute;
        width: 100%;
        height: 100%;
        transform-origin: bottom center;
    }
}

.curtain {
    width: 100%;
    height: 100vh;
    background-color: $color-primary;
    position: absolute;
    z-index: 999999;
    transform-origin: bottom center;
}

#listenNow {
    padding: 1rem 2rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 5px;
    border: none;
     background: #eee;
    opacity: 1;
    &:hover{
         background: #fff;
   
        // border: 2px solid $color-primary;
    }
    cursor: pointer;
    

    span {
        vertical-align: bottom;
        padding: 0;
        margin: 0;
        font-size: 1.1rem;
    }
    i {
        vertical-align: middle;
        font-size: 1.1rem;
    }
}

#listenOn {
    small {
        font-size: 0.8rem;
        // color: #eee;
        font-weight: 500;
        a {
            text-decoration: underline;
            font-weight: 600;
        }
    }
}

*:focus{
    outline: none;
}